import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { isAuthenticated } from 'utils/_auth.js';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { PATH_CART, PATH_GUEST } from 'utils/constants/paths.js';
import IconBike from 'images/bike.svg';
import TitleName from 'components/UI/Header/TitleName';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media (max-width: ${md}) {
    margin: 0 38px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #3b3516;
  margin: 25px 0 20px;
`;

const Message = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  margin-bottom: 8px;
`;

const Notice = styled.div`
  display: flex; /* 使用 flex 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 300px;
  background: #ffcccc;
  font-size: 16px;
  font-weight: 400;
  color: #fd0909;
  border: 1px solid #fd0909;
  border-radius: 4px;
  margin-bottom: 50px;
  padding: 8px;
`;

const Btn = styled.div`
  width: 230px;
  height: 60px;
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PaymentFail = ({ location, transNo }) => {
  const api = useAPI();

  const [reason, setReason] = useState('');

  const goCart = () => {
    api
      .reOrder(transNo)
      .then((res) => {
        if (!res) {
          return;
        }

        navigate(PATH_CART, {
          // state: { page: '' },
          replace: true,
        });
      })
      .catch((error) => {
        console.log('reorder exception =', error);
      });
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate(PATH_GUEST);
      return;
    }

    const params = new URLSearchParams(location.search);
    const reason = params.get('reason');
    if (reason != null) {
      setReason(reason);
    }
  }, []);

  return (
    <Container>
      <TitleName />
      <img src={IconBike} />
      <Title>支付失敗</Title>
      <Message>抱歉！您的訂單支付失敗</Message>
      <Notice>{reason}</Notice>
      <Btn onClick={() => goCart()}>重新下單</Btn>
    </Container>
  );
};

export default PaymentFail;
